import React from 'react'

export const BDF150 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1346"
    height="726"
    viewBox="0 0 1346 726"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 171 201"
      >
        <image
          width="171"
          height="201"
          href="../bdf-150/PA01.141.13e.1111_1.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 111 147"
      >
        <image
          width="111"
          height="147"
          href="../bdf-150/PA01.141.13e.1111_6.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 39 70"
      >
        <image
          width="39"
          height="70"
          href="../bdf-150/PA01.141.13e.1111_4.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 19 23"
      >
        <image
          width="19"
          height="23"
          href="../bdf-150/PA01.141.13e.1111_3.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 59 50"
      >
        <image
          width="59"
          height="50"
          href="../bdf-150/PA01.141.13e.1111_5.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 21 39"
      >
        <image
          width="21"
          height="39"
          href="../bdf-150/PA01.141.13e.1111_7.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 33 25"
      >
        <image
          width="33"
          height="25"
          href="../bdf-150/PA01.141.13e.1111_10.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 46 23"
      >
        <image
          width="46"
          height="23"
          href="../bdf-150/PA01.141.13e.1111_8.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 36 24"
      >
        <image
          width="36"
          height="24"
          href="../bdf-150/PA01.141.13e.1111_14.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 137 203"
      >
        <image
          width="137"
          height="203"
          href="../bdf-150/PA01.141.13e.1111_12.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 48"
      >
        <image
          width="40"
          height="48"
          href="../bdf-150/PA01.141.13e.1111_13.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../bdf-150/PA01.141.13e.1111_17.png"
        />
      </pattern>
      <pattern
        id="pattern-13"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../bdf-150/PA01.141.13e.1111_15.png"
        />
      </pattern>
      <pattern
        id="pattern-14"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../bdf-150/PA01.141.13e.1111_16.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_86"
      data-name="Group 86"
      transform="translate(-329 -220)"
    >
      <image
        id="PA01.141.13e.1111_11"
        width="404"
        height="538"
        transform="translate(820 408)"
        href="../bdf-150/PA01.141.13e.1111_11.png"
      />
      <image
        id="PA01.141.13e.1111_2"
        width="290"
        height="261"
        transform="translate(618 260)"
        href="../bdf-150/PA01.141.13e.1111_2.png"
      />
      <rect
        id="PA01.141.13e.1111_1"
        width="226"
        height="265"
        transform="translate(329 344)"
        fill="url(#pattern)"
        data-url="https://shopirbal.com/pt/produto/cremalheira-poliamida-130-150/"
        data-name="Cremalheira Poliamida 130-150"
      />
      <rect
        id="PA01.141.13e.1111_6"
        width="146"
        height="194"
        transform="translate(1369 220)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/tampa-do-motor-plastica-laranja/"
        data-name="Tampa do Motor Plástica Laranja"
      />
      <rect
        id="PA01.141.13e.1111_4"
        width="52"
        height="92"
        transform="translate(1208 225)"
        fill="url(#pattern-3)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.141.13e.1111_3"
        width="25"
        height="30"
        transform="translate(962 425)"
        fill="url(#pattern-4)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.141.13e.1111_5"
        width="78"
        height="66"
        transform="translate(1260 349)"
        fill="url(#pattern-5)"
      />
      <rect
        id="PA01.141.13e.1111_7"
        width="27"
        height="51"
        transform="translate(987 575)"
        fill="url(#pattern-6)"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + Cavilha"
      />
      <rect
        id="PA01.141.13e.1111_10"
        width="44"
        height="33"
        transform="translate(1173 368)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6003-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6003 2RS"
      />
      <rect
        id="PA01.141.13e.1111_8"
        width="61"
        height="30"
        transform="translate(752 570)"
        fill="url(#pattern-8)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6006-2rs/"
        data-name="Rolamento do Cubo do Balde 6006 2RS"
      />
      <image
        id="PA01.141.13e.1111_9"
        width="24"
        height="28"
        transform="translate(695 639)"
        href="../bdf-150/PA01.141.13e.1111_9.png"
      />
      <rect
        id="PA01.141.13e.1111_14"
        width="47"
        height="32"
        transform="translate(1299 613)"
        fill="url(#pattern-9)"
      />
      <rect
        id="PA01.141.13e.1111_12"
        width="180"
        height="268"
        transform="translate(497 634)"
        fill="url(#pattern-10)"
        data-url="https://shopirbal.com/pt/produto/volante-laranja/"
        data-name="Volante Laranja"
      />
      <rect
        id="PA01.141.13e.1111_13"
        width="52"
        height="63"
        transform="translate(1299 812)"
        fill="url(#pattern-11)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-160-com-freio/"
        data-name="Roda Maciça Ø160 com Freio"
      />
      <rect
        id="PA01.141.13e.1111_17"
        width="48"
        height="56"
        transform="translate(1418 500)"
        fill="url(#pattern-12)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.141.13e.1111_15"
        width="97"
        height="100"
        transform="translate(1418 626)"
        fill="url(#pattern-13)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
      <rect
        id="PA01.141.13e.1111_16"
        width="75"
        height="88"
        transform="translate(1600 540)"
        fill="url(#pattern-14)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
    </g>
  </svg>
)
